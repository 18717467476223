<!-- 拜访统计 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <el-date-picker v-model="select.month" type="month" placeholder="选择月" format="yyyy-MM" value-format="yyyy-MM" :clearable="false" @change="curr=1;getList()">
            </el-date-picker>
            <el-button-group>
                <el-button :type="customerOrSalesman==1?'primary':''" size="small" @click="handleChange(1)">按客户</el-button>
                <el-button :type="customerOrSalesman==2?'primary':''" size="small" @click="handleChange(2)">按业务员</el-button>
            </el-button-group>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column v-for="(h,idx) in headList" :key="idx" :prop="h.filed" :label="h.name" align="center" show-overflow-tooltip :width="(h.filed=='count'||h.filed=='name')?'150':'auto'" :fixed="idx==0?true:false">
                <template slot-scope="scope">
                    <span :style="{color:scope.row[h.filed]>0?'#F56C6C':'',fontWeight:scope.row[h.filed]>0?'bold':''}">{{scope.row[h.filed]}}</span>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            select: { month: '' },
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            customerOrSalesman: 1,
        }
    },
    mounted: function() {
        var myDate = new Date();
        var currentMonth1 = myDate.getMonth();
        var firstDay2 = new Date(myDate.getFullYear(), currentMonth1, 1)
        this.select['month'] = this.dataFormat(firstDay2);

        this.handleChange(1);
    },
    methods: {
        handleChange(customerOrSalesman) {
            this.curr = 1;
            this.customerOrSalesman = customerOrSalesman;
            this.getList()
        },
        getList: function() {
            let api = this.customerOrSalesman == 1 ? '/admin.AdminDay/tongJiByUser' : '/admin.AdminDay/tongJiByAdmin';
            this.http.post(api, {
                curr: this.curr,
                row: this.row,
                month: this.select.month,
            }).then(re => {
                this.list = re.data.date_count_list;
                this.headList = re.data.date_list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
                // this.$nextTick(() => {
                //     this.$refs.table.doLayout()
                // })
            })
        },
        dataFormat(time) {
            return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}`;
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
